import React from "react";
// import PNF from "../assets/images/404SVG/error.svg";
import Footer from "../components/Footer";
import Header from "../components/HeaderV2";

const browser = typeof window !== "undefined" && window;

const NotFound = () => {
  return (
    browser && (
      <>
        <Header />
        <div className="h-screen bg-blue-200 flex items-center">
          <div className="container flex flex-col items-center justify-center px-5 Text-balck">
            <div className="max-w-7xl">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div className="my-auto">
                  <div className="lg:text-9xl text-5xl font-dark font-bold">404</div>
                  <p className="mt-5 text-2xl md:text-3xl font-light leading-normal text-gray-800">
                    {/* Oops where you are ! either you've misplaced the URL or page has
                  vanished, but let's blame it on us. */}
                    Uh oh, page has vanished or misplaced url !!
                  </p>
                  <p className="mt-5 text-xl font-light leading-normal text-gray-800">
                    Hey web traveller, you've reached beyond WMT's limits.
                    <br />
                    Wanna{" "}
                    <a
                      href="mailto:php1@webmobtech.com,kashyapm@webmob.tech"
                      className="text-blue-400"
                    >
                      REPORT A PROBLEM
                    </a>
                    ! Or Search for another things
                  </p>
                </div>
                <div className="flex justify-center ">
                  <div className="w-full">
                    <img
                      src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/error.png"
                      alt="ErrorPage"
                      // title="ErrorPage"
                      width="600px"
                      height="600px"
                      loading="lazy"
                    />
                    {/* <PNF /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  );
};

export default NotFound
